import React, { Component } from 'react';

class PrivacyPolicy extends Component {

    render() {
        return (
            <section className="p-privacy-policy">
                <h1>Privacy Policy</h1>
                <p>This document describes the privacy policy for apps developed by Pontus Stjernström and provided by nocroft.se as tools for entertainment.</p>
                <h3>Personal Information</h3>
                <p>No user information is saved or sent using the Android apps.</p>
                <h3>Analysis of app usage</h3>
                <p>
                    No analysis is made of the user's behavior or inputs. 
                    No information used in the application, or fed into the app is saved. 
                    No data is forwarded about how the app is used. 
                    This information is only used by the app while it is active and disappears after you close the app.
                </p>
                <h3>Local files</h3>
                <p>
                    Some non-personal information (such as highscores) that are generated by the app itself will be saved locally on the device
                    until the app is uninstalled or its cache is cleared.
                    The information will not be shared or forwarded.
                </p>
            </section>
        );
    }
}

export default PrivacyPolicy;