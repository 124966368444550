import React, { Component } from 'react';

class About extends Component {

    render () {
        return (
            <div className="col-md-12 text-center">
                I really like manuls.
            </div>
        );
    }
}

export default About;