export default [
'later',
'tomorrow',
'tonight',
'next year',
'when you feel for it',
'whenever you are ready',
'sometime soon',
'anytime',
'at Christmas',
'at 4:20',
'until tomorrow',
'for three hours',
'until we can\'t do it anymore',
];