export default 
[
'sexy',
'hot',
'cute',
'sweet',
'pretty',
'nice',
'wonderful',
'marvelous',
'glorious',
'remarkable',
'dreamlike',
'warm'
]