export default [
'cutie',
'cutiepie',
'sweetheart',
'heart',
'sexy',
'beast',
'sexy beast',
'human',
'human individual',
'person',
];