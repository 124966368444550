export default [
'eat',
'sleep',
'go bowling',
'go for a dinner',
'look at Netflix and chill',
'party',
'go to the club',
'dance',
'take a bath',
'call me',
'go for a walk',
'meet at my place',
];