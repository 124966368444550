export default [
'smile',
'look',
'bicep',
'arm',
'drink',
'glass',
'nose',
'face',
'attitude',
'hair',
'intellect',
];